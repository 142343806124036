import fetch from 'unfetch';
import polyfills from '../polyfills';
import chunkLoader from './middlewares/chunk-loader';
import errorHandler from './middlewares/error-handler';
import playerJsonParser from './middlewares/player-json-parser';
import whitelistedEmbedValidation from './middlewares/whitelisted-embed-validation';
import getUuidFromPath from '../shared/utils/uuid-from-path';

function createFetchURL(pathname = window.location.pathname) {
  if (pathname.indexOf('/preview') !== -1) {
    return `/player/preview.json${window.location.search}`;
  }

  const isIE =
    !!window.document.documentMode || !!window.navigator.userAgent.match(/(MSIE|Trident)/i);
  const isIPadOS =
    !!window.navigator.platform &&
    window.navigator.platform === 'MacIntel' &&
    typeof window.navigator.standalone !== 'undefined';
  const isIOS =
    !!window.navigator.platform &&
    /iPad|iPhone|iPod/.test(window.navigator.platform);
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isOldIOS = /os (10|9|8|7|6)_/.test(userAgent) && (isIOS || isIPadOS);
  const isFirefoxMobile = userAgent.indexOf('firefox') > -1 && userAgent.indexOf('android') > -1;
  const isEdgeMobile = userAgent.indexOf('edg') > -1 && userAgent.indexOf('android') > -1;
  const isBB = userAgent.indexOf('blackberry') > -1 || userAgent.indexOf('bb') > -1;
  const hasPomoQueryParam = window.location.search.indexOf('pomo=1') !== -1;
  const querySeparator = window.location.search.indexOf('?') === -1 ? '?' : '&';
  let blockedBrowser = '';
  if (isIE) blockedBrowser = 'IE';
  if (isOldIOS) blockedBrowser = 'legacyiOS';
  if (isFirefoxMobile) blockedBrowser = 'FireFoxMobile';
  if (isEdgeMobile) blockedBrowser = 'EdgeMobile';
  if (isBB) blockedBrowser = 'BB';

  const skipPomoFlag = blockedBrowser && !hasPomoQueryParam ? `${querySeparator}pomo=0&pomo_reason=${blockedBrowser}` : '';

  // valid routes for the player '/:uuid.html', '/:uuid', '/:uuid/type/:type'
  const uuid = getUuidFromPath(pathname);
  const base = pathname.indexOf('/type/background') !== -1 ? '/background' : '/player';

  return `${base}/${uuid}.json${window.location.search}${skipPomoFlag}`;
}

const playerContextPromise = fetch(createFetchURL(), {
  headers: { referrer: document.referrer },
});

Promise.all([playerContextPromise, polyfills()])
  .then(([r]) => playerJsonParser(r))
  .then(whitelistedEmbedValidation)
  .then(chunkLoader)
  .catch(errorHandler);

import { listenForErrors } from '../../../player/components/utils/errorBoundary/logError.ts';

export default ({ type, payload }) => {
  const init = ({ default: module }) => module(payload);

  switch (type) {
    case 'player':
      return import(/* webpackChunkName: "player" */ '../../player/react-shell').then(init);
    case 'pomo-player':
      listenForErrors();
      return import(/* webpackChunkName: "player-pomo" */ '../../../player/index.tsx').then(init);
    case 'background':
      return import(/* webpackChunkName: "background" */ '../../background-player').then(init);
    case 'secure':
      return import(/* webpackChunkName: "access-code" */ '../../restricted-playback/access-code').then(
        init,
      );
    case 'restricted':
      return import(/* webpackChunkName: "whitelisted-embed" */ '../../restricted-playback/whitelisted-embed').then(
        init,
      );
    case 'unreleased':
      return import(/* webpackChunkName: "unreleased" */ '../../unreleased/react-shell').then(init);
    case 'error':
    default:
      return import(/* webpackChunkName: "error-page" */ '../../load-error').then(init);
  }
};

// Partial copy-paste of: '../../../player/components/utils/languageModule/languageModule';
const removeLocale = (langString) => langString.split('-')[0];
const findPreferredLanguage = () => {
  const userLanguages = window.navigator.languages || [window.navigator.language || 'en'];
  const supportedLanguages = ['en', 'fr'];

  const preferredLang = userLanguages.reduce((selectedLang, nextLang) => {
    const removedLocaleLang = removeLocale(nextLang);
    return !selectedLang && supportedLanguages.indexOf(removedLocaleLang) > -1
      ? removedLocaleLang
      : selectedLang;
  }, '');

  return preferredLang;
};

export default (e) => {
  console.error(e);

  const language = findPreferredLanguage();
  const errorMessage = {
    title: language === 'fr'
      ? 'Un problème est survenu, veuillez réessayer plus tard.'
      : 'A problem occurred, please try again later.',
    message: '',
  };

  return import(/* webpackChunkName: "error-page" */ '../../load-error').then(
    ({ default: module }) => module(errorMessage),
  );
};

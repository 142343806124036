import fetch from 'unfetch';
import ErrorStackParser from 'error-stack-parser';
import getUuidFromPath from '../../../../client/shared/utils/uuid-from-path';
import { ROLLBAR_SCRUB_FIELDS } from '../../../../constants';
import UserAgentChecker from '../../../../client/shared/utils/user_agent_checker';

function shouldIgnoreError(error: Error, args?: Record<string, unknown>): boolean {
  const source = args?.source as string | undefined;
  if (
    error.message.startsWith(
      "Failed to execute 'querySelectorAll' on 'Document': 'div:has(> iframe[id=",
    )
  ) {
    return true;
  }
  if (
    // Ignore specific error messages starting with known pattern
    source &&
    (source?.startsWith('chrome-extension://') || source?.startsWith('moz-extension://'))
  ) {
    // Ignore errors from browser extensions
    return true;
  }
  const webpackImportRegex = /Loading (CSS )?chunk \d{1,2} failed/;
  if (webpackImportRegex.test(error.message)) {
    // Ignore errors for webpack imports network failures
    return true;
  }

  return false;
}

export function logError({
  error,
  allowBots = true,
  store,
  args,
}: {
  error: Error;
  allowBots?: boolean;
  store?: RootState;
  args?: Record<string, unknown>; // Any object
}): void {
  if (!allowBots && UserAgentChecker.is_bot()) {
    return;
  }

  // Check ignore conditions
  if (shouldIgnoreError(error, args)) {
    return;
  }

  const url = `//${__HOST_PLAYBACK}/api/error`;
  const now = new Date().getTime();

  const queryParams = new URLSearchParams(document.location.search);
  ROLLBAR_SCRUB_FIELDS.forEach((key) => queryParams.delete(key));

  const payload = {
    client: {
      exception: {
        name: error.name,
        message: error.message,
      },
      url: document.location.pathname,
      referrer: document.referrer,
      queryString: queryParams.toString(),
      runtimeMs: now - window.__startTime,
      timestamp: Math.round(now / 1000),
      javascript: {
        code_version: __PLAYER_HASH, // Frontend git sha (code version)
        browser: window.navigator.userAgent,
        language: window.navigator.language,
        cookieEnabled: window.navigator.cookieEnabled,
        screen: { width: window.screen.width, height: window.screen.height },
        plugins: Array.prototype.slice.call(window.navigator.plugins).map(({ name, description }): {
          name: string;
          description: string;
        } => ({
          name,
          description,
        })),
      },
    },
    stack: ErrorStackParser.parse(error),
    store: store || { config: { playerUuid: getUuidFromPath() } },
    args,
  };

  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export function listenForErrors(): void {
  window.onerror = (
    errorMessage: string | Event,
    source: string,
    lineno: number,
    colno: number,
    error: Error = new Error('Unknown error'),
  ): void => {
    logError({ error, args: { uncaughtError: true, errorMessage, source, lineno, colno } });
  };
}

import isEmbedAllowed from '../../shared/utils/embed-referrer-checker';

export default (playerJson) => {
  const { hasAccessCodes, playerAttributes } = playerJson.payload;
  let whitelistedEmbedDomains = playerAttributes && playerAttributes.whitelisted_embed_domains;

  // Account for different payloads for pomo and modern
  if (playerJson.type === 'pomo-player') {
    ({ whitelistedEmbedDomains } = playerJson.payload);
  }

  if (
    whitelistedEmbedDomains &&
    !isEmbedAllowed(document.referrer, whitelistedEmbedDomains) &&
    !hasAccessCodes
  ) {
    playerJson.type = 'restricted';
  }

  return playerJson;
};

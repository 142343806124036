export default function isEmbedAllowed(referrer = '', whitelistedEmbedDomains = []) {
  if (!whitelistedEmbedDomains || whitelistedEmbedDomains.length === 0) {
    return true;
  }
  let referrerURI;
  try {
    referrerURI = new URL(referrer);
  } catch (e) {
    return false;
  }

  // Create union regex of allowedDomains
  const regex = new RegExp(
    whitelistedEmbedDomains
      .map((url) => url.replace('://www.', '://'))
      .join('|')
      // Allow wildcard subdomains by replacing any ://*. with ://(|.*.)
      .replace(/:\/\/\*./g, '://(|.*.)')
      // Allow either http or https versions by replacing http:// or https with http(|s)://(|www.)
      // Allow either www or not www
      .replace(/http(|s):\/\//g, 'http(|s)://(|www.)'),
  );

  return regex.test(`${referrerURI.protocol}//${referrerURI.host}`);
}

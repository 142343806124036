var UserAgentChecker = (function() {

  var getIOSVersion = function() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      return parseInt((navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10);
    } else {
      return false;
    }
  };

  return {
    html5VideoPlaybackAvailable: function() {
      return this.is_android() || this.is_ios() || this.is_bb10() || this.is_wp() || this.is_xbox();
    },

    is_android: function() {
      return navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("silk") > -1;
    },

    is_android_before: function(version) {
      var userVersion = navigator.userAgent.toLowerCase().match(/android (\d+(?:\.\d+)+)/);
      if (userVersion && userVersion.length === 2 && parseFloat(userVersion[1]) < version) {
        return true;
      }
      return false;
    },

    is_android_chrome: function() {
      return UserAgentChecker.is_android() && UserAgentChecker.is_chrome();
    },

    is_bot: function() {
      return /bot\b/.test(navigator.userAgent.toLowerCase());
    },

    is_ios_ipad: function() {
      return (navigator.userAgent.toLowerCase().indexOf("ipad") > -1);
    },

    is_ios: function() {
      return (this.is_ios_mobile() || this.is_ios_ipad());
    },

    is_ios_mobile: function() {
      return (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipod") > -1);
    },

    is_mobile: function() {
      return this.is_ios() || this.is_android() || this.is_bb10() || this.is_bb6_or_bb7() || this.is_wp();
    },

    is_ios_before: function(version) {
      var userVersion = getIOSVersion();

      if (!userVersion) { return false; }
      return (userVersion < version);
    },

    is_ios_gte: function(version) {
      var userVersion = getIOSVersion();

      if (!userVersion) { return false; }
      return (userVersion >= version);
    },

    is_bb10: function() {
      return (navigator.userAgent.toLowerCase().indexOf("bb10") > -1);
    },

    is_bb_below_10: function() {
      return false;
    },

    is_wp: function() {
      var userAgent = navigator.userAgent.toLowerCase();
      if(userAgent.indexOf("iemobile") === -1) {
        return false;
      }

      // check for strings like msie 10
      var msie = userAgent.match(/(msie )\d+/g);
      if(msie !== null) {
        // remove all digits from the above result, leaving the version number
        var msieVersion = msie[0].replace(/\D/g, '');
        return !!msieVersion && parseInt(msieVersion, 10) >= 9;
      }

      // WP could be using a different format where 'msie' is not present
      var iemobileVersion = userAgent.match(/(iemobile\/)(\d|\.)+/g);
      if(iemobileVersion === null) {
        return false;
      }
      iemobileVersion = iemobileVersion[0].replace(/\/|[a-zA-Z]/g, '');

      return !!iemobileVersion && parseFloat(iemobileVersion) >= 9;
    },

    is_bb6_or_bb7: function() {
      var userAgent = navigator.userAgent.toLowerCase();
      return (userAgent.indexOf("blackberry") > -1) && (userAgent.indexOf("version/6") > -1 || userAgent.indexOf("version/7") > -1);
    },

    is_xbox: function() {
      return (navigator.userAgent.toLowerCase().indexOf("xbox") > -1);
    },

    is_ie: function() {
      return navigator.userAgent.toLowerCase().indexOf("msie") > -1 || navigator.userAgent.toLowerCase().indexOf("trident") > -1 || navigator.userAgent.toLowerCase().indexOf("edge") > -1;
    },

    is_ie7: function() {
      var userAgent = navigator.userAgent.toLowerCase();
      var ieVersion = parseInt((userAgent.match(/trident\/(\d+)/) || [])[1], 10) + 4;
      return document.documentElement.className.indexOf('ie7') > -1 || userAgent.indexOf('msie 7') > -1
      // IE has issues sometimes with emulating IE 8 - so we should make them behave as if they're in IE 7 since the only
      // times those browsers drop to IE 8 is usually due to a parent page being rendered in compatibility mode - and that
      // means that IE 9/10/11 isn't properly running IE 8 stuff:
        || (ieVersion > 8 && document.documentMode === 8);
    },

    is_ie_9: function() {
      return document.documentElement.className.indexOf('ie9') > -1;
    },

    is_ie_10: function() {
      return navigator.userAgent.toLowerCase().indexOf('msie 10') > -1;
    },

    is_ie_11: function() {
      return !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));
    },

    is_edge: function() {
      return navigator.userAgent.toLowerCase().indexOf("edge") > -1;
    },

    is_ie_11_or_edge: function() {
      return this.is_ie_11() || this.is_edge();
    },

    is_ie_10_or_below: function() {
      return this.is_ie_9() || this.is_ie_10();
    },

    is_ie8_or_below: function() {
      return document.documentElement.className.indexOf('lt-ie9') > -1;
    },

    is_firefox: function() {
      return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    },

    is_mac: function() {
      return navigator.appVersion.indexOf('Mac') !== -1;
    },

    is_windows_desktop: function() {
      return navigator.userAgent.toLowerCase().indexOf("windows") > -1 && navigator.userAgent.toLowerCase().indexOf("windows phone") === -1;
    },

    is_chrome: function() {
      return navigator.userAgent.toLowerCase().indexOf("chrome") > -1 || navigator.userAgent.indexOf('CriOS') > -1;
    },

    is_chrome_desktop: function() {
      return this.is_chrome() && !UserAgentChecker.is_ios() && !UserAgentChecker.is_android();
    },

    is_safari: function() {
      return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.indexOf('CriOS') === -1;
    },

    is_safari_gte: function(minimumVersion) {
      if (!this.is_safari()) {
        return false;
      }

      const safariVersionMatches = navigator.userAgent.match(/Version\/(\d+)/);
      if (!safariVersionMatches) {
        return false;
      }

      const userAgentVersion = parseInt(safariVersionMatches[1], 10);
      return userAgentVersion >= minimumVersion;
    },

    is_legacy_player: function() {
      return /^\/legacy\//.test(window.location.pathname);
    },

    is_hls_capable: function () {
      return this.is_ios() || this.is_safari() || !!window.MediaSource;
    },
  };
})();

export { UserAgentChecker as default };
